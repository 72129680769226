import { log } from "../../utils";
import { IAttributes, INodeV3 } from "../models";
import { IAttachment } from "../attachment";
import { Page } from "..";
import { Iframe } from "./iframe";

export const namespaces = {
  html: "http://www.w3.org/1999/xhtml",
  svg: "http://www.w3.org/2000/svg",
  mathml: "http://www.w3.org/1998/Math/MathML",
  xul: "http://www.mozilla.org/keymaster/gatekeeper/there.is.only.xul",
  xbl: "http://www.mozilla.org/xbl",
};

export const lookupNamespaces = {
  "http://www.w3.org/1999/xhtml": "html",
  "http://www.w3.org/2000/svg": "svg",
  "http://www.w3.org/1998/Math/MathML": "mathml",
  "http://www.mozilla.org/keymaster/gatekeeper/there.is.only.xul": "xul",
  "http://www.mozilla.org/xbl": "xbl",
};

/** capture an instance of a dom tree */
export class Serializer {
  private svgContainer: HTMLElement = null;
  private svgs: Record<string, HTMLElement> = {};

  constructor(private page: Page | Iframe) {}

  public cleanup() {
    this.svgContainer?.remove();
  }

  public stylesheet(sheet: CSSStyleSheet): string {
    return sheet
      ? Array.from(sheet.cssRules, (rule) => rule.cssText).join("\n")
      : "";
  }

  public text(node: Node) {
    if (
      node.parentNode.nodeName === "STYLE" ||
      node.parentNode.nodeName === "SCRIPT"
    ) {
      return "";
    } else {
      return node.nodeValue;
    }
  }

  public node(node: Node, props: IAttachment): INodeV3 {
    if (node.nodeType === node.ELEMENT_NODE) {
      return this.element(node as Element, props);
    } else {
      return {
        id: (props.id = this.page.idp.add(node)),
        name: node.nodeName,
        text: props.text,
      };
    }
  }

  public element(node: Element, props: IAttachment): INodeV3 {
    const info: INodeV3 = {
      id: (props.id = this.page.idp.add(node)),
      name: node.nodeName,
      attrs: props.attributes,
    };

    const ns = lookupNamespaces[(node as Element).namespaceURI];

    if (ns !== "html") {
      info.ns = ns;
    }

    try {
      switch (node.nodeName) {
        case "SCRIPT":
          info.attrs = undefined;
          break;
        case "STYLE":
          info.sheet = {
            rules: this.stylesheet((node as HTMLStyleElement).sheet),
          };
          break;
        case "LINK": {
          let isSheet = false;

          try {
            isSheet = !!(node as HTMLLinkElement).sheet?.cssRules;
          } catch {}

          if (isSheet) {
            info.name = "STYLE";
            info.sheet = {
              href: (node as HTMLLinkElement).href,
              rules: this.stylesheet((node as HTMLStyleElement).sheet),
            };

            delete info.attrs.href;
          } else if (
            (node as HTMLLinkElement).as
              ? (node as HTMLLinkElement).as === "script"
              : (node as HTMLLinkElement).href?.endsWith(".js")
          ) {
            delete info.attrs;
            info.name = "#comment";
          }

          break;
        }
        case "IFRAME": {
          // no need for url if we have access
          if ((node as HTMLIFrameElement).contentDocument) {
            delete info.attrs.src;
            delete info.attrs.sandbox;

            if (info.attrs.srcdoc) {
              info.attrs.srcdoc = "";
            }
          }

          break;
        }
        // svg embed, they are lowercase
        case "use": {
          const href = (
            (node as SVGUseElement).getAttribute("href") ||
            (node as SVGUseElement).getAttribute("xlink:href")
          )?.split("#");

          if (href.length === 2) {
            // init or dom reset
            if (!this.svgContainer) {
              this.svgContainer = this.page.getDocument().createElement("div");
              this.svgContainer.setAttribute("style", "display:none");
              this.svgContainer.setAttribute(
                "data-use",
                "auvious-svg-container"
              );

              for (const href in this.svgs) {
                this.svgContainer.appendChild(this.svgs[href]);
              }
            }

            if (!this.svgContainer.isConnected) {
              setTimeout(
                () =>
                  this.page.getDocument().body.appendChild(this.svgContainer),
                0
              );
            }

            if (href[0] && !(href[0] in this.svgs)) {
              this.svgs[href[0]] = null;
              this.page
                .getWindow()
                .fetch(href[0])
                .then(async (response) => {
                  const svgText = await response.text();
                  const parser = new DOMParser();
                  const svgDoc = parser.parseFromString(
                    svgText,
                    "image/svg+xml"
                  );

                  this.svgs[href[0]] = svgDoc.documentElement;
                  this.svgContainer.appendChild(this.svgs[href[0]]);
                })
                .catch((ex) => {
                  console.warn(
                    `SVG resource at ${href[0]} could not be fetched and will not be rendered remotely: `,
                    ex?.message
                  );
                });
            }

            info.attrs ??= {};
            info.attrs.href = info.attrs["xlink:href"] = "#" + href[1];
          }
        }
      }
    } catch (error) {
      log(error, node);
    }

    return info;
  }

  public attributes(elm: Element) {
    const attrs: IAttributes = {};

    for (let i = 0; i < elm.attributes.length; i++) {
      const attr = elm.attributes[i];

      attrs[attr.name] =
        attr.name.startsWith("on") && elm[attr.name] instanceof Function
          ? undefined
          : attr.value;
    }

    return attrs;
  }

  public attribute(elm: Element, name: string) {
    return name.startsWith("on") && elm[name] instanceof Function
      ? undefined
      : elm.getAttribute(name);
  }
}
