import { FilterType, IFilter } from "../../model";
import { log } from "../../utils";
import { getAttachment } from "../attachment";
import { IAttributes, INodeV3 } from "../models";
import { Iframe } from "./iframe";
import { Page } from "./page";

enum SelectorType {
  CLASS = 1,
  COMPOSITE = 2,
  ID = 3,
  TAG = 4,
}

export interface IPrivateFilter extends IFilter {
  is: SelectorType;
  hasAttr: boolean;
}

export const Transparent1px =
  "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";

let RandomText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. \
Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat. Vel turpis nunc eget lorem dolor sed viverra ipsum nunc. \
Ipsum suspendisse ultrices gravida dictum fusce ut. Mi eget mauris pharetra et. Aliquam faucibus purus in massa tempor. \
Nisi vitae suscipit tellus mauris a diam maecenas. Augue lacus viverra vitae congue eu. Feugiat in ante metus dictum at tempor commodo. `;

const letterRx = /\S/gu;
const spacesRx = /\b +\b/gu;
const replacerRx = /\b([x ]+)\b/gu;

export function filterText(text: string) {
  // try to replace with placeholder of equal total rendered length
  return text.replace(letterRx, "x").replace(spacesRx, "x");
}

const keepAttribute = new Set([
  "style",
  "class",
  "id",
  "width",
  "height",
  "type",
  "slot",
  "name",
]);

export function filterAttributes(attrs: IAttributes, node: HTMLElement) {
  const out: IAttributes = {};
  let empty = true;

  for (const name in attrs) {
    if (keepAttribute.has(name)) {
      out[name] = attrs[name];
      empty = false;
    }
  }

  // a slot needs its name
  if (out.name && node.tagName !== "SLOT") {
    out.name = undefined;
  }

  return empty ? undefined : out;
}

export function filterNode(info: INodeV3, node: HTMLElement) {
  if (node.tagName === "IMG") {
    info.attrs.width =
      info.attrs.width || (node as HTMLImageElement).offsetWidth + "";
    info.attrs.height =
      info.attrs.height || (node as HTMLImageElement).offsetHeight + "";
  }

  if (info.attrs) info.attrs = filterAttributes(info.attrs, node);
  if (info.text) info.text = filterText(info.text);
}

// starting point to look random
let offset = 0;

export function randomizeText(text: string) {
  if (text.length > RandomText.length) {
    RandomText = RandomText.repeat(Math.ceil(text.length / RandomText.length));
  }

  return text.replace(replacerRx, (match) => {
    const part = RandomText.substring(offset, (offset += match.length));

    return offset >= RandomText.length
      ? part + " " + RandomText.substring(0, (offset -= RandomText.length))
      : part;
  });
}

export class Filter {
  private filtered = new WeakMap<Node, number>();

  /** initialize with already parsed filters */
  constructor(
    private page: Page | Iframe,
    public filters: IPrivateFilter[] = []
  ) {}

  public add(...filters: IFilter[]) {
    const rejected = [];

    for (const f of filters) {
      const pf: IPrivateFilter = {
        is: SelectorType.COMPOSITE,
        selector: f.selector.trim(),
        hasAttr: false,
        type: f.type,
      };

      if (/^[a-z0-9-]+$/i.test(pf.selector)) {
        pf.is = SelectorType.TAG;
        pf.selector = pf.selector.toUpperCase();
      } else if (/^#[a-z0-9-_]+$/i.test(pf.selector)) {
        pf.is = SelectorType.ID;
        pf.selector = pf.selector.slice(1);
      } else if (/^\.[a-z0-9-_]+$/i.test(pf.selector)) {
        pf.is = SelectorType.CLASS;
        pf.selector = pf.selector.slice(1);
      } else if (/\[[^\]]+\]/i.test(pf.selector)) {
        pf.hasAttr = true;
      }

      try {
        // test if valid
        this.queryElements(document.body, pf.selector, pf.is);
        this.filters.push(pf);
      } catch (_) {
        rejected.push(f);
      }
    }

    if (rejected.length) {
      log("These filters are invalid", rejected);
    }
  }

  public scan(node: Node) {
    if (this.hasFilter(node) || node.nodeType !== node.ELEMENT_NODE) {
      return;
    }

    for (const filter of this.filters) {
      const matches = this.queryElements(
        node as Element,
        filter.selector,
        filter.is
      );

      for (let i = 0; i < matches.length; i++) {
        this.filterNode(matches[i], filter.type);
      }
    }
  }

  /**
   * @param parent - filter this node
   */
  public filterNode(parent: Node, type: FilterType) {
    this.setFilter(parent, type);
  }

  public setFilter(node: Node, type: FilterType) {
    return this.filtered.set(node, type);
  }

  public getFilter(node: Node): FilterType {
    return (
      this.filtered.get(node) || getAttachment(node)?.filter || FilterType.None
    );
  }

  public hasFilter(node: Node): boolean {
    return this.filtered.has(node) || getAttachment(node)?.filter > 0;
  }

  private queryElements(elm: Element, selector: string, type: SelectorType) {
    switch (type) {
      case SelectorType.CLASS:
        return elm.getElementsByClassName(selector);
      case SelectorType.COMPOSITE:
        return elm.querySelectorAll(selector);
      case SelectorType.ID: {
        const res = this.page.getDocument().getElementById(selector);
        return res ? [res] : [];
      }
      case SelectorType.TAG:
        return elm.getElementsByTagName(selector);
    }
  }
}
