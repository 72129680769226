/**
 * an wrapper around document tree walker with extra features
 */
export class Walker {
  private walker: TreeWalker;

  public ended = false;

  constructor(root: Node) {
    this.walker = document.createTreeWalker(root, NodeFilter.SHOW_ALL);
  }

  public step() {
    if (this.walker.firstChild()) {
      return;
    }

    this.skip();
  }

  /**
   * skip whole subtree
   */
  public skip() {
    if (this.walker.nextSibling()) {
      return;
    }

    while (this.walker.parentNode()) {
      if (this.walker.nextSibling()) {
        return;
      }
    }

    this.ended = true;
  }

  /**
   * visit the subtree while ignoring depth info
   * @param visit
   */
  public sprint(visit: (node: Node) => void) {
    this.shadowSprint(visit, this.walker);
    this.ended = true;
  }

  private shadowSprint(visit: (node: Node) => void, walker: TreeWalker) {
    do {
      visit(walker.currentNode);

      if ((walker.currentNode as HTMLElement).shadowRoot) {
        this.shadowSprint(
          visit,
          document.createTreeWalker(
            (walker.currentNode as HTMLElement).shadowRoot,
            NodeFilter.SHOW_ALL
          )
        );
      }
    } while (!this.ended && walker.nextNode());
  }
}
