import { MirrorPage } from "..";
import { FilterType } from "../../model";
import { log } from "../../utils";
import { Iframe } from "../capture/iframe";
import { CobrowserKey } from "../capture/model";
import { Page } from "../capture/page";

export interface IInputUpdateEvent {
  target: number;
  /** whether it just initializes the element, otherwise is an user event */
  init?: true;
  value?: any;
  selected?: number[];
  checked?: boolean;
  submit?: boolean;
  /** the change event */
  change?: boolean;
}

const INPUT_UPDATE_KEY = `${CobrowserKey}_input_update`;

export class Input {
  private inputhandler = (ev: InputEvent) => this.capture(ev);

  /** do not allow input when this is listening */
  private focushandler = (ev: FocusEvent) =>
    (ev.target as HTMLElement).blur?.();

  public enabled = false;
  private skipHook = false;

  constructor(
    private page: Page | Iframe | MirrorPage,
    private listener: (ev: IInputUpdateEvent) => void
  ) {
    if (!this.page.isOwner) {
      this.onFocus();
    } else {
      this.injectContext();
    }
  }

  private updateInput = (
    target: HTMLFormElement,
    update: IInputUpdateEvent,
    options: HTMLOptionElement[] = [],
    win = window
  ) => {
    if (update.selected?.length) {
      const selected = new Set(options);

      Array.from(target.selectedOptions)
        .concat(options)
        .filter((opt) => opt)
        .forEach(
          (opt: HTMLOptionElement) => (opt.selected = selected.has(opt))
        );
    } else if (update.submit) {
      if (target.requestSubmit) target.requestSubmit();
      else target.dispatchEvent(new win.SubmitEvent("submit"));
    } else if (update.change) {
      target.dispatchEvent(
        new win.Event("change", { bubbles: true, cancelable: true })
      );
    } else {
      if (update.checked !== undefined) {
        target.checked = update.checked;
      }

      if (update.value !== undefined) {
        target.value = update.value;
      }
    }
  };

  private injectContext() {
    this.page.getWindow()[INPUT_UPDATE_KEY] = this.updateInput.bind(
      this.page.getWindow()
    );

    // if (!this.page.getWindow()[INPUT_UPDATE_KEY]) {
    //   const script = this.page.getDocument().createElement("script");
    //   script.innerHTML = `
    //     window.${INPUT_UPDATE_KEY}=${this.updateInput.toString()}
    //   `;
    //   this.page.getDocument().head.appendChild(script);
    //   script.remove();
    // }
  }

  private onFocus() {
    this.page.getDocument().addEventListener("focus", this.focushandler, true);
  }

  private offFocus() {
    this.page
      .getDocument()
      .removeEventListener("focus", this.focushandler, true);
  }

  private unhook = () => {};

  private hook() {
    try {
      this.unhook();

      const hooks: [HTMLElement, string][] = [
        [this.page.getWindow().HTMLInputElement.prototype, "value"],
        [this.page.getWindow().HTMLInputElement.prototype, "checked"],
        [this.page.getWindow().HTMLSelectElement.prototype, "value"],
        [this.page.getWindow().HTMLTextAreaElement.prototype, "value"],
        [this.page.getWindow().HTMLOptionElement.prototype, "selected"],
      ];

      const listener = (target: HTMLElement) => {
        if (this.enabled && !this.skipHook) {
          setTimeout(() => {
            this.capture({
              target:
                target.tagName === "OPTION" ? target.parentElement : target,
              timeStamp: Date.now(),
            });
          }, 0);
        }
      };

      const originals = hooks.map(([proto, key]) => {
        const original = this.page
          .getWindow()
          .Object.getOwnPropertyDescriptor(proto, key);

        this.page.getWindow().Object.defineProperty(proto, key, {
          set(value) {
            listener(this);
            original.set?.call(this, value);
          },
        });

        return original;
      });

      this.unhook = () => {
        this.unhook = () => {};
        originals.forEach((desc, i) =>
          this.page
            .getWindow()
            .Object.defineProperty(hooks[i][0], hooks[i][1], desc)
        );
      };
    } catch (ex) {
      log(ex);
    }
  }

  /** externalize internal target because event's target changes to the custom element */
  private onShadowInput = (ev: InputEvent) => {
    // @ts-expect-error
    ev.internalTarget = ev.target;
  };

  private onSubmit = (ev: SubmitEvent) => {
    ev.preventDefault();

    const target = this.page.idp.getId(ev.target as HTMLFormElement);

    if (target && this.enabled)
      this.listener({
        target,
        submit: true,
      });
  };

  private onChange = (ev: Event) => {
    const target = this.page.idp.getId(ev.target as HTMLFormElement);

    if (target && this.enabled)
      this.listener({
        target,
        change: true,
      });
  };

  /** track input updates */
  public track(target: Document | ShadowRoot) {
    if (target === this.page.getDocument()) {
      target.addEventListener("input", this.inputhandler);
    } else {
      target.addEventListener("input", this.onShadowInput);
      return;
    }

    if (!this.page.isOwner) {
      target.addEventListener("submit", this.onSubmit);
      target.addEventListener("change", this.onChange);
    }

    if (!this.enabled) {
      if (this.page.isOwner) {
        this.hook();
      } else {
        this.offFocus();
      }

      this.enabled = true;
    }
  }

  /** stop tracking updates */
  public untrack() {
    if (this.page.isOwner) {
      this.unhook();
    } else {
      this.offFocus();
      this.onFocus();
    }

    this.page.getDocument()?.removeEventListener("input", this.inputhandler);
    this.enabled = false;
  }

  public dispatch(update: IInputUpdateEvent) {
    const target = this.page.idp.getNode(update.target) as HTMLFormElement &
      HTMLSelectElement;

    if (!target) {
      return;
    }

    this.skipHook = true;

    const options = update.selected?.length
      ? update.selected.map(
          (id) => this.page.idp.getNode(id) as HTMLOptionElement
        )
      : [];

    if (this.page.isOwner) {
      this.page
        .getWindow()
        [INPUT_UPDATE_KEY](target, update, options, this.page.getWindow());
    } else {
      this.updateInput(target, update, options, this.page.getWindow());
    }

    this.skipHook = false;
  }

  public capture(ev: Partial<InputEvent>) {
    const elm: HTMLFormElement & HTMLSelectElement =
      // @ts-expect-error
      ev.internalTarget || (ev.target as HTMLFormElement & HTMLSelectElement);

    if (elm.type === "file" || elm.type === "password") {
      return true;
    }

    const target = this.page.idp.getId(elm);

    if (
      target !== 0 &&
      // on renderer filtered inputs are disabled
      (!(this.page as Page).filter ||
        (this.page as Page).filter.getFilter(ev.target as Node) ===
          FilterType.None)
    ) {
      const data: IInputUpdateEvent = {
        target,
      };

      if ((elm as HTMLSelectElement).selectedOptions) {
        data.selected = Array.from(
          (elm as HTMLSelectElement).selectedOptions
        ).map((opt) => this.page.idp.getId(opt));
      } else {
        data.checked = elm.checked;
        data.value = elm.value;
      }

      // check for manual captures
      if (ev.timeStamp === undefined) {
        // ignore empty inputs
        if (!(data.selected?.length || data.checked === true || data.value)) {
          return;
        }

        data.init = true;
      }

      this.listener(data);
    }
  }
}
