import { MirrorPage } from "..";
import { log } from "../../utils";
import { Iframe } from "../capture/iframe";
import { Page } from "../capture/page";

export interface ISheetUpdateEvent {
  target: number;
  op: "insertRule" | "deleteRule" | "replace" | "replaceSync";
  css: string;
  index: number;
}

export class Sheet {
  public enabled = false;

  constructor(
    private page: Page | Iframe | MirrorPage,
    private listener: (ev: ISheetUpdateEvent) => void
  ) {}

  private unhook = () => {};

  public track() {
    try {
      this.untrack();

      const self = this;
      const proto = this.page.getWindow().CSSStyleSheet.prototype;

      const originals = {
        insertRule: proto.insertRule,
        deleteRule: proto.deleteRule,
        replace: proto.replace,
        replaceSync: proto.replaceSync,
      };

      proto.insertRule = function (rule: string, index = 0) {
        if (self.enabled) {
          setTimeout(
            () =>
              self.capture(
                (this as CSSStyleSheet).ownerNode as HTMLStyleElement,
                "insertRule",
                rule,
                index
              ),
            0
          );
        }

        return originals.insertRule.call(this, rule, index);
      };

      proto.deleteRule = function (index: number) {
        if (self.enabled) {
          setTimeout(
            () =>
              self.capture(
                (this as CSSStyleSheet).ownerNode as HTMLStyleElement,
                "deleteRule",
                "",
                index
              ),
            0
          );
        }

        originals.deleteRule.call(this, index);
      };

      proto.replace = function (text: string) {
        if (self.enabled) {
          setTimeout(
            () =>
              self.capture(
                (this as CSSStyleSheet).ownerNode as HTMLStyleElement,
                "replace",
                text
              ),
            0
          );
        }

        return originals.replace.call(this, text);
      };

      proto.replaceSync = function (text: string) {
        if (self.enabled) {
          setTimeout(
            () =>
              self.capture(
                (this as CSSStyleSheet).ownerNode as HTMLStyleElement,
                "replaceSync",
                text
              ),
            0
          );
        }

        return originals.replaceSync.call(this, text);
      };

      this.unhook = () => {
        this.unhook = () => {};
        proto.insertRule = originals.insertRule;
        proto.deleteRule = originals.deleteRule;
        proto.replace = originals.replace;
        proto.replaceSync = originals.replaceSync;
      };

      this.enabled = true;
    } catch (ex) {
      log(ex);
    }
  }

  public untrack() {
    this.unhook();
    this.enabled = false;
  }

  public async dispatch(update: ISheetUpdateEvent) {
    const target = this.page.idp.getNode(update.target) as HTMLStyleElement &
      HTMLLinkElement;

    if (target) {
      if (update.op === "deleteRule") {
        target.sheet.deleteRule(update.index);
      } else {
        await target.sheet[update.op](update.css, update.index);
      }
    }
  }

  public capture(
    target: HTMLStyleElement | HTMLLinkElement,
    op: ISheetUpdateEvent["op"],
    css: string,
    index = 0
  ) {
    const targetId = this.page.idp.getId(target);

    if (targetId && target.isConnected) {
      this.listener({ target: targetId, op, css, index });
    }
  }
}
