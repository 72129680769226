import { FilterType } from "../../model";
import { getAttachment } from "../attachment";
import { Iframe } from "../capture/iframe";
import { Page } from "../capture/page";
import { MirrorPage } from "../render/page";

interface User {
  id: string;
  endpoint: string;
}

export interface ICursorEvent {
  type:
    | "mousemove"
    | "mousedown"
    | "mouseup"
    | "click"
    | "mouseover"
    | "mouseout";
  clientX: number;
  clientY: number;
  target: number;
  button: number;
  time: number;
}

export interface ICursorClickEvent extends ICursorEvent {
  type: "click";
  /** descriptive label for index of replay */
  innerTextPrefix?: string;
}

export type TCursorEventList = ICursorEvent | ICursorClickEvent;

export interface IRemoteCursorMoved {
  type: "mousemove";
  left: number;
  top: number;
  user?: User;
}

const preventDefaultInputType = new Set([
  "checkbox",
  "file",
  "password",
  "radio",
  "submit",
]);

export class Cursor {
  private onmousemove = (ev) => this.handleMoveEvent(ev);
  private onmouseover = (ev) => this.handleEvent(ev);
  private onmouseout = (ev) => this.handleEvent(ev);
  private onmousedown = (ev) => this.handleEvent(ev);
  private onmouseup = (ev) => this.handleEvent(ev);
  private onclick = (ev) => this.handleEvent(ev);

  public enabled = false;

  public suppressed: { [key in ICursorEvent["type"]]: boolean } = {
    mouseout: false,
    mouseover: false,
    mousemove: false,
    mousedown: false,
    mouseup: false,
    click: false,
  };

  /** when the difference between an event's screenX and clientX differs it means:
   *  - window moved
   *  - window resized
   *  - some dom changed, or inner window like toolbar changed, resulting in root moving to other position
   *  detect this and recalculate left, top positions of current frame relative to root
   */
  private diffScreenClientX = 0;
  private diffScreenClientY = 0;
  private lastX = 0;
  private lastY = 0;

  /** last target moved on */
  private lastTarget: HTMLElement = this.page.getDocument().body;

  constructor(
    private page: Page | Iframe | MirrorPage,
    private listener: (ev: TCursorEventList) => void,
    private options?: { prevent: boolean }
  ) {
    this.options ??= { prevent: true };

    // copy settings of parent's cursor
    if (!this.page.isOwner && !this.page.isRoot) {
      this.suppressed = {
        ...(this.page as MirrorPage).parent.cursor.suppressed,
      };
    }
  }

  public suppress(event: ICursorEvent["type"], value: boolean) {
    this.suppressed[event] = value;
    (this.page as MirrorPage).forEachFrame?.((frame) =>
      frame.cursor.suppress(event, value)
    );
  }

  /** externalize internal target because event's target changes to the custom element */
  private onShadowInput = (ev: InputEvent) => {
    // @ts-expect-error
    ev.internalTarget = ev.target;
  };

  public track(target: Document | ShadowRoot) {
    if (target === this.page.getDocument()) {
      target.addEventListener("mouseout", this.onmouseout);
      target.addEventListener("mouseover", this.onmouseover);
      target.addEventListener("mousemove", this.onmousemove);
      target.addEventListener("mousedown", this.onmousedown);
      target.addEventListener("mouseup", this.onmouseup);
      target.addEventListener("click", this.onclick);
    } else {
      target.addEventListener("mouseout", this.onShadowInput);
      target.addEventListener("mouseover", this.onShadowInput);
      target.addEventListener("mousemove", this.onShadowInput);
      target.addEventListener("mousedown", this.onShadowInput);
      target.addEventListener("mouseup", this.onShadowInput);
      target.addEventListener("click", this.onShadowInput);

      return;
    }

    this.enabled = true;
  }

  public untrack() {
    this.page.getWindow()?.removeEventListener("mousemove", this.onmousemove);

    this.page.getWindow()?.removeEventListener("mouseout", this.onmouseout);
    this.page.getWindow()?.removeEventListener("mouseover", this.onmouseover);
    this.page.getWindow()?.removeEventListener("mousedown", this.onmousedown);
    this.page.getWindow()?.removeEventListener("mouseup", this.onmouseup);
    this.page.getWindow()?.removeEventListener("click", this.onclick);

    this.enabled = false;
  }

  public dispatch(event: TCursorEventList) {
    const target = this.page.idp.getNode(event.target) as HTMLElement;

    if (!target) {
      return;
    }

    // select in safari opens options and closes only when it loses focus
    if (this.page.isOwner && target.nodeName !== "SELECT") {
      const mouseEvent = new MouseEvent(event.type, {
        bubbles: true,
        cancelable: true,
        composed: true,
        clientX: event.clientX,
        clientY: event.clientY,
        button: event.button,
      });

      target.dispatchEvent(mouseEvent);

      // possibly undo this event if they share a common ancestor that listens to it
      // if (event.type === "mouseout")
      //   this.lastTarget.dispatchEvent(
      //     new MouseEvent("mouseover", {
      //       bubbles: true,
      //       cancelable: true,
      //     })
      //   );
    }
  }

  /** window has changed dimensions, find cursor absolute position */
  private calibrate(ev: MouseEvent) {
    this.page.requestAbsolutePosition();

    this.diffScreenClientX = ev.screenX - ev.clientX;
    this.diffScreenClientY = ev.screenY - ev.clientY;
  }

  private handleEvent(ev: MouseEvent) {
    if (this.suppressed[ev.type]) {
      ev.preventDefault();
      ev.stopPropagation();
      return false;
    }

    // @ts-expect-error
    const target = ev.internalTarget || ev.target;

    // all synthetics events are not synced
    if (!ev.isTrusted) {
      // to have activeElement updated
      if (ev.type === "mouseup") {
        (target as HTMLElement)?.focus?.();
      }

      return false;
    }

    if (!this.page.isOwner) {
      // trigger event only on remote side, except certain elements
      if (
        !(
          (target.tagName === "INPUT" &&
            !preventDefaultInputType.has(target.type)) ||
          target.tagName === "SELECT" ||
          target.tagName === "TEXTAREA"
        ) &&
        ev.type === "click"
      ) {
        ev.preventDefault();
        ev.stopPropagation();
      }

      if (getAttachment(target)?.filter === FilterType.DisplayRandomized) {
        ev.preventDefault();
        ev.stopPropagation();
        return false;
      }
    }

    const id = this.page.idp.getId(target as Node);

    if (
      id !== 0 &&
      (!(this.page as Page).filter ||
        (this.page as Page).filter.getFilter(target as Node) < 2)
    ) {
      this.listener({
        // @ts-expect-error
        type: ev.type,
        button: ev.button,
        clientX: ev.clientX + this.page.absoluteLeft,
        clientY: ev.clientY + this.page.absoluteTop,
        target: id,
        innerTextPrefix:
          ev.type === "click"
            ? (target as HTMLElement)?.innerText?.trimLeft().substring(0, 50)
            : undefined,
      });
    }

    return !this.options.prevent;
  }

  private handleMoveEvent(ev: MouseEvent) {
    // all synthetics events are not synced
    if (!ev.isTrusted || this.suppressed[ev.type]) {
      return false;
    }

    const deltaX = Math.abs(ev.clientX - this.lastX);
    const deltaY = Math.abs(ev.clientY - this.lastY);

    if (deltaX + deltaY < 3) {
      return !this.options.prevent;
    }

    // @ts-expect-error
    this.lastTarget = ev.internalTarget || ev.target;
    const id = this.page.idp.getId(this.lastTarget as Node);

    if (
      id !== 0 &&
      (!(this.page as Page).filter ||
        (this.page as Page).filter.getFilter(this.lastTarget as Node) < 2)
    ) {
      // quick way to detect if window has changed position to calibrate mouse position in iframes
      // needs to be 1, because it changes often probably due to some internal float rounding
      if (
        Math.abs(this.diffScreenClientX - (ev.screenX - ev.clientX)) > 1 ||
        Math.abs(this.diffScreenClientY - (ev.screenY - ev.clientY)) > 1
      ) {
        this.calibrate(ev);
      }

      this.lastX = ev.clientX;
      this.lastY = ev.clientY;

      this.listener({
        // @ts-expect-error
        type: ev.type,
        button: ev.button,
        clientX: ev.clientX + this.page.absoluteLeft,
        clientY: ev.clientY + this.page.absoluteTop,
        target: id,
      });
    }

    return !this.options.prevent;
  }
}
