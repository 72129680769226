import { Cursor } from "../events/cursor";
import { Input } from "../events/input";
import { Location } from "../events/location";
import { Observer } from "../events/observer";
import { Scroll } from "../events/scroll";

import { log } from "../../utils";
import { HighLighter } from "../events/highlight";
import { Sheet } from "../events/sheet";
import { PageUpdateV3 } from "../models";
import { PageBase, SIGNATURE_KEY } from "./base";
import { Filter, IPrivateFilter } from "./filter";
import { DocumentRegistered, PleaseRegister } from "./message-events.types";
import { Serializer } from "./serializer";

export interface IFrameOptions {
  targetId: number;
  parentId: number;
  filters?: IPrivateFilter[];
  allowOrigins?: string[];
  user?: {
    id: string;
    endpoint: string;
    metadata?: {
      role: string;
      name: string;
    };
  };
}

enum RegistatrionState {
  UNREGISTERED,
  REGISTERING,
  REGISTERED,
}

export class Iframe extends PageBase {
  public isOwner = true;
  public isRoot = false;

  private registrationState = RegistatrionState.UNREGISTERED;

  constructor(
    public target: HTMLIFrameElement | HTMLElement,
    options?: Pick<IFrameOptions, "allowOrigins">
  ) {
    super(target, null);

    this.allowedOrigins = (options?.allowOrigins || []).join("\n");

    this.observer = new Observer(this);
    this.serializer = new Serializer(this);

    this.input = new Input(this, (ev) =>
      this.messenger.sendPageUpdate(this.event("InputUpdate", ev))
    );

    this.cursor = new Cursor(this, (ev) =>
      this.messenger.sendPageUpdate(this.event("Cursor", ev))
    );

    this.scroll = new Scroll(this, (ev) =>
      this.messenger.sendPageUpdate(this.event("ScrollUpdate", ev))
    );

    this.location = new Location(this, (ev) => {
      this.messenger.sendPageUpdate(this.event("Navigation", ev));
    });

    this.sheet = new Sheet(this, (ev) => {
      this.messenger.sendPageUpdate(this.event("SheetUpdate", ev));
    });

    this.highlighter = new HighLighter(this, (ev) =>
      this.messenger.sendPageUpdate(this.event("HighLight", ev))
    );

    this.getWindow()[SIGNATURE_KEY] = this.signature;

    this.messenger.sendStatusRequest();
  }

  // private log(...args: any[]) {
  //   console.warn(location.href, "IFRAME", this, ...args);
  // }

  /** window messages */
  public onPleaseRegister(ev: PleaseRegister) {
    if (this.registrationState === RegistatrionState.UNREGISTERED) {
      this.registrationState = RegistatrionState.REGISTERING;
      this.messenger.sendRegistrationRequest(ev.id);
    }
  }

  public onDocumentRegistered(ev: DocumentRegistered) {
    this.registrationState = RegistatrionState.REGISTERED;

    this.documentId = ev.id;
    this.allowedOrigins += ev.init.allowOrigins?.join("\n") || "";
    this.user = ev.init.user;

    this.options = {
      ...ev.init,
      allowOrigins: this.allowedOrigins.split("\n"),
    };

    this.init();
  }

  public async init() {
    this.getWindow().addEventListener("pagehide", () => this.cleanup(), {
      once: true,
    });

    if (this.getDocument().readyState !== "complete") {
      log("Iframe has not loaded yet", this.getWindow().location.href);

      await new Promise((resolve) =>
        this.getWindow().addEventListener("load", resolve, { once: true })
      );
    }

    log("Initializing new iframe", this.getWindow().location.href);

    this.messenger.sendPageUpdate(
      this.event(
        "Document",
        {
          id: this.documentId,
          target: this.options.targetId,
        },
        this.options.parentId
      )
    );

    this.filter = new Filter(this, this.options.filters || []);

    this.track();
  }

  public async render(ev: PageUpdateV3) {
    switch (ev.type) {
      case "InputUpdate":
        this.handleRemoteInput(ev);
        break;
      case "ScrollUpdate":
        this.handleRemoteScroll(ev);
        break;
      case "Cursor":
        this.handleRemoteCursor(ev);
        break;
      case "HighLight":
        await this.highlighter.dispatch(ev.payload);
        break;
    }
  }

  public requestAbsolutePosition() {
    this.messenger.sendAbsolutePositionRequest();
  }

  /** @param lite - merges events to form a page instance */
  public async flush(lite = false) {}

  private track() {
    this.location?.track();
    this.sheet.track();
    this.observer.track(this.getDocument().documentElement);
  }

  public createIframe(
    target: HTMLIFrameElement,
    options: Pick<IFrameOptions, "allowOrigins">
  ) {
    return new Iframe(target, options);
  }

  public cleanup(shallow = false) {
    if (this.cleared) {
      return;
    }

    this.location?.untrack();
    this.observer?.untrack();
    this.serializer?.cleanup();
    this.sheet.untrack();

    super.cleanup(shallow);
  }
}
