import { AuviousCommon } from "@auvious/common";
import debug from "debug";

export const log = debug("auvious:cobrowser");

export const wait = (n: number) =>
  new Promise((resolve) => setTimeout(resolve, n));

export async function getSession(client: AuviousCommon, id: string) {
  return client.apiResourceFactory("cobrowser/api/session").getById(id);
}

export function isSafari() {
  const ua = navigator.userAgent.toLowerCase();
  return !ua.includes("chrome") && ua.includes("safari");
}
