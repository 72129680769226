import { MirrorPage, Page } from "..";
import { FilterType } from "../../model";
import { Iframe } from "../capture/iframe";

export interface IRangeEvent {
  endContainer: number;
  endOffset: number;
  startContainer: number;
  startOffset: number;
}

/** use until typings available */
interface IHighLight {
  constructor(): IHighLight;
  add(range: Range): IHighLight;
  clear(): void;
}

export class HighLighter {
  public enabled = false;

  private selectionhandler = (ev) => this.handleEvent(ev);
  // any click will trigger selection, so avoid these
  private wasLastCollapsed = true;

  private highlight: IHighLight = null;

  constructor(
    private page: Page | Iframe | MirrorPage,
    private listener: (ev: { ranges: IRangeEvent[] }) => void
  ) {}

  public track() {
    this.handleEvent(new Event("selectionchange"));

    // @ts-expect-error
    const Highlight: typeof IHighLight = this.page.getWindow().Highlight;
    const highlightId = "auvious-cobrowser-highlight";

    if (Highlight) {
      if (!this.page.getDocument().getElementById(highlightId)) {
        const style = this.page.getDocument().createElement("style");
        style.setAttribute("id", highlightId);
        style.setAttribute("type", "text/css");
        style.innerHTML = `
        ::highlight(${highlightId}) {
          background-color: peachpuff;
        }
      `;

        (this.page as Iframe).filter?.filterNode(style, FilterType.NoDisplay);
        this.page.getDocument().head.appendChild(style);
      }

      if (!this.highlight) {
        // clear any previous instance
        this.highlight?.clear();
        this.highlight ??= new Highlight();

        this.page
          .getWindow()
          // @ts-expect-error
          .CSS.highlights.set("auvious-cobrowser-highlight", this.highlight);
      }
    }

    this.page
      .getDocument()
      .addEventListener("selectionchange", this.selectionhandler);

    this.enabled = true;
  }

  public untrack() {
    if (!this.enabled) {
      return;
    }

    this.page
      .getDocument()
      ?.removeEventListener("selectionchange", this.selectionhandler);
    this.enabled = false;
  }

  public async dispatch({ ranges }: { ranges: IRangeEvent[] }) {
    let range: Range;
    const selection = this.page.getDocument().getSelection();

    if (ranges.length) {
      range = new Range();
      const start = this.page.idp.getNode(ranges[0].startContainer);
      const end = this.page.idp.getNode(ranges[0].endContainer);
      range.setStart(start, ranges[0].startOffset);
      range.setEnd(end, ranges[0].endOffset);
    }

    this.highlight ? this.highlight.clear() : selection.removeAllRanges();

    if (range)
      this.highlight ? this.highlight.add(range) : selection.addRange(range);
  }

  private handleEvent(ev: Event) {
    const selection = this.page.getWindow().getSelection();
    const range = selection.rangeCount ? selection.getRangeAt(0) : null;
    const isCollapsed = range?.collapsed || selection.isCollapsed;

    if (!isCollapsed) {
      this.listener({
        ranges: [
          {
            endContainer: this.page.idp.getId(range.endContainer),
            endOffset: range.endOffset,
            startContainer: this.page.idp.getId(range.startContainer),
            startOffset: range.startOffset,
          },
        ],
      });
    } else if (!this.wasLastCollapsed) {
      this.listener({
        ranges: [],
      });
    }

    this.wasLastCollapsed = isCollapsed;
  }
}
