import { Cursor } from "../events/cursor";
import { Input } from "../events/input";
import { Scroll } from "../events/scroll";
import { Location } from "../events/location";
import { Idp } from "../idp";
import {
  ICursorUpdate,
  IInputUpdate,
  IScrollUpdate,
  PageUpdateV3,
} from "../models";
import { Walker } from "../walker";
import { removeAttachment } from "../attachment";
import { RegistrationRequest, StatusRequest } from "./message-events.types";
import { Sheet } from "../events/sheet";
import { HighLighter } from "../events/highlight";
import { CobrowserKey } from "./model";
import { Messenger } from "./messenger";
import { Observer } from "../events/observer";
import { Serializer } from "./serializer";
import { Filter } from "./filter";
import type { IFrameOptions, Iframe } from "./iframe";

export interface IUser {
  endpoint: string;
}

export interface IEventOptions {
  scroll?: boolean;
  input?: boolean;
  cursor?: boolean;
  highlight?: boolean;
}

export interface IRootPage {
  render(ev: PageUpdateV3): Promise<void>;
}

export const SIGNATURE_KEY = `${CobrowserKey}_signature`;
export const ID_KEY = `${CobrowserKey}_id`;

export abstract class PageBase {
  public isOwner: boolean;
  public documentId: number = 0;
  public isRoot: boolean;
  public allowedOrigins = "";
  public options: IFrameOptions;
  /** allows parent to match current window to frameElement as same origin messages share window source */
  public signature = this.generateId();

  // distance from viewport origin
  public absoluteLeft = 0;
  public absoluteTop = 0;

  public readonly idp: Idp = new Idp();

  public observer: Observer;
  public cursor: Cursor;
  public input: Input;
  public scroll: Scroll;
  public highlighter: HighLighter;
  public location: Location;
  public sheet: Sheet;
  public serializer: Serializer;
  public filter: Filter;

  protected ecounter = 0;
  protected defaultView: Document["defaultView"];

  /** common time for all events produced during flushing */
  protected flushingTime = 0;

  static SyncRequestPeriod = 5000;
  protected lastSync = Date.now();

  public messenger: Messenger;

  constructor(public readonly target: HTMLElement, public user: IUser = null) {
    if (this.target.tagName === "IFRAME") {
      this.defaultView = (
        this.target as HTMLIFrameElement
      ).contentDocument?.defaultView;
    } else {
      this.defaultView = this.target.ownerDocument.defaultView;
    }

    this.observer = new Observer(this);

    this.messenger = new Messenger(this);

    this.idp.set(this.getDocument(), 1);
  }

  public onSetEvents(events: IEventOptions) {
    this.setEvents(events);
  }

  /** @deprecated */
  public get enabledEvents(): IEventOptions {
    return {
      cursor: this.cursor.enabled,
      input: this.input.enabled,
      scroll: this.scroll.enabled,
      highlight: this.highlighter.enabled,
    };
  }

  public setEvents(events: IEventOptions) {
    if (events.input) {
      this.input.track(this.getDocument());
    } else if (events.input === false) {
      this.input.untrack();
    }

    if (events.cursor) {
      this.cursor.track(this.getDocument());
    } else if (events.cursor === false) {
      this.cursor.untrack();
    }

    if (events.scroll) {
      this.scroll.track();
    } else if (events.scroll === false) {
      this.scroll.untrack();
    }

    if (events.highlight) {
      this.highlighter.track();
    } else if (events.highlight === false) {
      this.highlighter.untrack();
    }

    const iframes = this.getDocument().getElementsByTagName("iframe");

    for (let i = 0; i < iframes.length; i++) {
      if (this.messenger.maybeUsesOldMode(iframes[i].contentWindow)) {
        this.messenger.sendSetEvents(events, iframes[i].contentWindow);
      }
    }
  }

  public getWindow() {
    return this.defaultView.window;
  }

  public getDocument() {
    return this.defaultView.document;
  }

  public handleRemoteInput(event: IInputUpdate) {
    this.input.dispatch(event.payload);
  }

  public handleRemoteScroll(ev: IScrollUpdate) {
    this.scroll.dispatch(ev.payload, ev.time);
  }

  public handleRemoteCursor(ev: ICursorUpdate) {
    this.cursor.dispatch(ev.payload);
  }

  public abstract requestAbsolutePosition(): void;
  public abstract render(ev: PageUpdateV3);

  public resetAbsolutePosition(coords: { left: number; top: number }) {
    this.absoluteLeft = coords.left;
    this.absoluteTop = coords.top;

    const iframes = this.getDocument().getElementsByTagName("iframe");

    for (let i = 0; i < iframes.length; i++) {
      if (iframes[i].contentWindow) {
        const rect = iframes[i].getBoundingClientRect();
        this.messenger.sendAbsolutePositionReset(
          {
            left: this.absoluteLeft + rect.left,
            top: this.absoluteTop + rect.top,
          },
          iframes[i].contentWindow
        );
      }
    }
  }

  public onStatusRequest(ev: StatusRequest, source: WindowProxy) {
    const iframe = this.lookupFrame(source, ev.signature);
    const targetId = this.idp.getId(iframe);

    if (targetId) {
      this.messenger.sendPleaseRegister(
        this.generateId(),
        iframe.contentWindow
      );
    }
  }

  public onRegistrationRequest(ev: RegistrationRequest, source: WindowProxy) {
    const iframe = this.lookupFrame(source, ev.signature);
    const targetId = this.idp.getId(iframe);

    if (targetId) {
      this.messenger.sendDocumentRegistered(
        ev.id,
        {
          targetId,
          parentId: this.documentId,
          allowOrigins: this.options.allowOrigins,
          filters: this.filter.filters,
          user: this.options.user,
        },
        iframe.contentWindow
      );
    }
  }

  public async onFlushRequest() {
    this.flushingTime = this.getTime();

    try {
      const captureTime = Date.now();
      const records = await this.observer.flush();

      if (records) {
        records.metrics = { captureTime: Date.now() - captureTime };
        this.messenger.sendPageUpdate(this.event("Mutations", records));
      }

      this.messenger.sendFlushResponse(this.observer.postProcess().events);
    } finally {
      this.flushingTime = 0;
    }
  }

  /** @deprecated */
  public onDocumentReady(source: WindowProxy) {
    const target = this.lookupFrame(source);

    if (target) {
      this.messenger.sendInitCapture(
        {
          documentId: this.generateId(),
          targetId: this.idp.getId(target),
          parentId: this.documentId,
          endpoint: this.user.endpoint,
          filters: this.filter.filters,
        },
        target.contentWindow
      );
    }
  }

  protected lookupFrame(source: WindowProxy, signature?: number) {
    const iframes = this.getDocument().getElementsByTagName("iframe");

    for (let i = 0; i < iframes.length; i++) {
      if (
        (iframes[i].contentDocument &&
          iframes[i].contentWindow[SIGNATURE_KEY] === signature) ||
        iframes[i].contentWindow === source
      ) {
        return iframes[i];
      }
    }

    return null;
  }

  public generateId() {
    return Math.trunc(((Date.now() % 10 ** 8) + Math.random()) * 10000);
  }

  // resolve cyclic dependency
  public abstract createIframe(
    target: HTMLIFrameElement,
    options: Pick<IFrameOptions, "allowOrigins">
  ): Iframe;

  /**
   * Cover cases:
   * 1. Iframe relocated
   *   - onload called: no attachment/ no id / with id; or trackFrame
   * 2. Iframe reloaded
   * 3. Iframe removed
   * 4. Iframe tracked
   *
   * @param iframe
   * @returns void
   */
  public async trackFrame(target: HTMLIFrameElement) {
    // has been removed while waiting to load
    if (!target.isConnected || this.cleared) {
      return;
    }

    let sameOriginChild: Iframe;
    const id = this.generateId();

    if (target.contentDocument) {
      target.contentWindow[ID_KEY] = { id };

      sameOriginChild = this.createIframe(target, {
        allowOrigins: this.options.allowOrigins,
      });
    } else {
      // cross origin iframe might use the old script
      this.messenger.sendInitCapture(
        {
          documentId: id,
          targetId: this.idp.getId(target),
          parentId: this.documentId,
          endpoint: this.user.endpoint,
          filters: this.filter.filters,
        },
        target.contentWindow
      );
    }

    target.addEventListener(
      "load",
      () => {
        // remove duplicate
        sameOriginChild?.cleanup();
        this.trackFrame(target);
      },
      { once: true }
    );

    this.messenger.sendPleaseRegister(id, target.contentWindow);
  }

  public cleared = false;

  public get closed() {
    return this.cleared || this.getWindow()?.closed !== false;
  }

  public cleanup(shallow = false) {
    this.cleared = true;

    this.location?.untrack();
    this.scroll.untrack();
    this.input.untrack();
    this.cursor.untrack();

    this.cleanIdp();

    this.messenger.cleanup();
  }

  /** clean everything related to an id */
  protected cleanIdp() {
    // this.queue = this.queue[0]?.type === "PageMetadata" ? [this.queue[0]] : [];
    this.idp.reset();
    this.idp.set(this.getDocument(), 1);
  }

  protected getTime() {
    return Date.now();
  }

  public event<T extends PageUpdateV3>(
    type: T["type"],
    payload?: T["payload"],
    document = this.documentId
  ): T {
    return {
      type,
      time: this.flushingTime || this.getTime(),
      document,
      endpoint: this.user.endpoint,
      payload,
    } as T;
  }
}
