import { Iframe } from "../capture/iframe";
import { Page } from "../capture/page";
import { MirrorPage } from "../render/page";

export interface IScrollEvent {
  /** a target of zero uses the scollingElement or the window */
  target: number;
  /** whether it just initializes the element, otherwise is an user event */
  init?: true;
  scrollX: number;
  scrollY: number;
  height?: number;
  width?: number;
}

export interface IScrollUpdatedEvent extends IScrollEvent {}

export class Scroll {
  static preventDuration = 1000;

  public enabled = false;

  private scrollupdatedhandler = (ev) => this.capture(ev);

  private preventScroll = new WeakMap<
    Node | Window,
    { until: number; x: number; y: number; w: number; h: number }
  >();

  private preventDispatch = new WeakMap<Node | Window, number>();

  // eslint-disable-next-line no-useless-constructor
  constructor(
    private page: Page | Iframe | MirrorPage,
    private listener: (ev: IScrollUpdatedEvent) => void
  ) {}

  public track() {
    if (this.enabled) {
      return;
    }

    this.page
      .getWindow()
      .addEventListener("scroll", this.scrollupdatedhandler, true);

    this.enabled = true;
  }

  public untrack() {
    this.page
      .getWindow()
      ?.removeEventListener("scroll", this.scrollupdatedhandler, true);

    this.enabled = false;
  }

  public dispatch(event: IScrollUpdatedEvent, time: number) {
    const target = event.target
      ? (this.page.idp.getNode(event.target) as Element)
      : this.page.getDocument();

    const scrollingElement = event.target
      ? (target as Element)
      : this.page.getDocument().scrollingElement || this.page.getWindow();

    if (!scrollingElement || this.preventDispatch.get(target) > time) {
      return;
    }

    this.preventScroll.set(target, {
      until: Date.now() + Scroll.preventDuration,
      x: event.scrollX,
      y: event.scrollY,
      w: event.width,
      h: event.height,
    });

    this.scroll(
      scrollingElement,
      event.scrollX,
      event.scrollY,
      event.width,
      event.height
    );
  }

  public isScrollable(elm: Element) {
    return elm.scrollLeft || elm.scrollTop;
  }

  private scroll(
    target: Element | Window,
    left: number,
    top: number,
    width: number,
    height: number
  ) {
    if (left <= width && top <= height) {
      const scrollingElement = Number.isInteger(
        (target as Element).scrollHeight
      )
        ? (target as Element)
        : this.page.getDocument().scrollingElement;

      if (width)
        left =
          (left / width) *
          (scrollingElement.scrollWidth - scrollingElement.clientWidth);

      if (height)
        top =
          (top / height) *
          (scrollingElement.scrollHeight - scrollingElement.clientHeight);
    }

    target.scroll({
      left,
      top,
      behavior: "instant",
    });
  }

  public capture(ev: Partial<Event>) {
    const target = ev.target as Element | Document;
    const targetId = this.page.idp.getId(target);

    if (!targetId) {
      return;
    }

    const scrollingElement =
      target === this.page.getDocument()
        ? this.page.getDocument().scrollingElement || this.page.getWindow()
        : (target as Element);

    let left: number, top: number, width: number, height: number;

    if (scrollingElement === this.page.getWindow()) {
      left = Math.floor(scrollingElement.scrollX);
      top = Math.floor(scrollingElement.scrollY);
      width =
        this.page.getDocument().documentElement.scrollWidth -
        this.page.getDocument().documentElement.clientWidth;
      height =
        this.page.getDocument().documentElement.scrollHeight -
        this.page.getDocument().documentElement.clientHeight;
    } else {
      left = Math.floor((scrollingElement as Element).scrollLeft);
      top = Math.floor((scrollingElement as Element).scrollTop);
      width =
        (scrollingElement as Element).scrollWidth -
        (scrollingElement as Element).clientWidth;
      height =
        (scrollingElement as Element).scrollHeight -
        (scrollingElement as Element).clientHeight;
    }

    const isDocument =
      target === this.page.getDocument() ||
      target === this.page.getDocument().scrollingElement;

    const preventTarget = isDocument ? this.page.getDocument() : target;
    const prevent = this.preventScroll.get(preventTarget);

    if (!prevent || prevent.until <= Date.now()) {
      this.preventDispatch.set(
        preventTarget,
        Date.now() + Scroll.preventDuration
      );

      this.listener({
        target: isDocument ? 0 : targetId,
        scrollX: left,
        scrollY: top,
        height,
        width,
        init: !!ev.timeStamp || undefined,
      });
    } else if (left !== prevent.x || top !== prevent.y) {
      this.scroll(scrollingElement, prevent.x, prevent.y, prevent.w, prevent.h);
    }
  }
}
