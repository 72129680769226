import { getAttachment, removeAttachment, setAttachment } from "./attachment";

export class Idp {
  public counter = 0;

  private nodes: Map<number, Node> = new Map();

  public getId(node: Node): number {
    return node ? getAttachment(node)?.id || 0 : 0;
  }

  public getNode(id: number): Node {
    return this.nodes.get(id) || null;
  }

  public removeId(id: number) {
    this.nodes.delete(id);
  }

  public removeNode(node: Node) {
    this.nodes.delete(getAttachment(node)?.id);
  }

  /** add an id to a node without one */
  public add(node: Node): number {
    const props = getAttachment(node);

    if (!props.id) {
      this.nodes.set((props.id = ++this.counter), node);
    }

    return props.id;
  }

  public set(node: Node, id: number) {
    const props = setAttachment(node, null, null);
    this.nodes.set((props.id = id), node);
    this.counter = Math.max(id, this.counter);
    return props;
  }

  public reset() {
    for (const node of this.nodes.values()) {
      removeAttachment(node);
    }

    this.nodes = new Map();
    this.counter = 0;
  }
}
