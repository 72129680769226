import { FilterType } from "../model";
import { IAttributes } from "./models";

/** our unique property key for a node */
const nodeKey = Symbol("auvious");

// to be attached to every node
export interface IAttachment {
  id: number;
  text: string;
  attributes: IAttributes;
  filter: FilterType;
}

export function setAttachment(
  node: Node,
  attributes: IAttributes,
  text: string,
  filter = FilterType.None
) {
  return (node[nodeKey] = {
    id: 0,
    attributes,
    text,
    filter,
  });
}

export function getAttachment(node: Node): IAttachment {
  return node[nodeKey];
}

export function hasAttachment(node: Node) {
  return !!node[nodeKey];
}

export function removeAttachment(node: Node) {
  node[nodeKey] = undefined;
}
