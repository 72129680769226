import { Iframe } from "../iframe";

export interface CobrowsingOptions {
  allowOrigins?: string[];
}

export function enableCobrowsing(options?: CobrowsingOptions) {
  console.log("Enabling embedded cobrowsing with options: ", options, document);

  const page = new Iframe(document.documentElement, {
    allowOrigins: options?.allowOrigins,
  });
}
