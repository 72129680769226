import { MirrorPage, Page } from "..";
import { Iframe } from "../capture/iframe";

export interface ILocationEvent {
  synthetic: boolean;
  href: string;
  base: string;
}

export interface ILocationUpdatedEvent extends ILocationEvent {}

export class Location {
  public isTracking = false;

  private locationupdatedhandler = (ev) => this.handleLocationEvent(ev);

  // works on anchors
  private preventNavigation = (ev: Event) => {
    if (!this.page.isOwner) {
      ev.preventDefault();
      // @ts-ignore
      ev.returnValue = "";
    }
    // @ts-expect-error
    else if (document.activeElement?.href) {
      // @ts-expect-error
      const target = new URL(document.activeElement?.href);

      if (target.hostname !== window.location.hostname) {
        ev.preventDefault();
        // @ts-ignore
        ev.returnValue = "";
      }
    }
  };

  constructor(
    private page: Page | Iframe | MirrorPage,
    private listener: (ev: ILocationUpdatedEvent) => void = () => {}
  ) {
    this.page
      .getWindow()
      .addEventListener("beforeunload", this.preventNavigation);
  }

  public track() {
    this.handleLocationEvent();

    if (this.isTracking) {
      return;
    }

    this.page
      .getWindow()
      .addEventListener("popstate", this.locationupdatedhandler);
    this.isTracking = true;
  }

  public untrack() {
    removeEventListener("beforeunload", this.preventNavigation);

    if (!this.isTracking) {
      return;
    }

    this.page
      .getWindow()
      ?.removeEventListener("popstate", this.locationupdatedhandler);
    this.isTracking = false;
  }

  private handleLocationEvent(ev?: Event) {
    this.listener({
      synthetic: !ev,
      href: this.page.getDocument().location.href,
      base: this.page.getDocument().baseURI,
    });
  }
}
